.ListGroupItem {
  height: 30px;
}

.css-ls8r52 > .ps-menu-button {
  padding-left: 18px;
}

div.ps-submenu-content.ps-open.css-1fosjbk {
  width: 220px;
}

.collapsedMenu {
  direction: rtl;

  span.ps-menu-icon.css-15v83ee {
    position: absolute;
    right: 6px;
    margin-right: 0px;
  }
}
