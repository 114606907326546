ul.pagination {
  display: flex;
  justify-content: center;
  gap: 18px;
}

ul.pagination li a {
  text-decoration: none;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Pretendard";
}

.selected {
  opacity: 1;
  border: solid 1px rgb(114, 114, 114);;
  background-color: rgb(114, 114, 114);;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

i.xi-angle-right,
i.xi-angle-left {
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  color: black;
}

ul.pagination li:not(.selected) li:hover,
i.xi-angle-right:hover,
i.xi-angle-left:hover {
  color: rgb(114, 114, 114);;
}
