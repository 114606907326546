.modal-30w {
    min-width: 30%;
  }
  
.external-api-integration .table-table {
    max-height: 30vh;
    overflow: auto;
}

.external-api-integration .table-header {
    position: sticky;
    top: 0;
    background-color: rgb(114, 114, 114);
  color: white;
  border-color: rgb(199, 199, 199);
}

.external-api-integration .table-header tr {
    vertical-align: middle;
}