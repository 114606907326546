h1,
h2,
h3,
h4 {
  margin: 0.1rem 0;
}

h1 {
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

h2 {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  padding-left: 20px;
}

h3 {
  font-size: 1rem !important;
  padding-left: 40px;
}

h4 {
  font-size: 0.8rem !important;
  font-style: italic;
  padding-left: 60px;
}

.table-header {
  position: sticky;
  top: 0;
  background-color: rgb(114, 114, 114);
  color: white;
  border-color: rgb(199, 199, 199);
}

.table-body {
  border-color: rgb(199, 199, 199);
}

.table-body a {
  color: inherit;
  text-decoration: unset;
  font-weight: bold;
}

.table-header tr {
  vertical-align: middle;
}

.table-table {
  overflow: auto;
  text-align: center;
  margin-left: 15px;
  //   border: 1px solid rgb(153, 153, 153);
}

.nav-pills {
  gap: 5px;
}

.nav-pills .nav-link {
  border: 1px solid var(--bs-nav-pills-link-active-bg) !important;
}

.nav-pills .nav-link:not(.active) {
  color: var(--bs-nav-pills-link-active-bg);
}