@import './CommonPage.scss';

.rtu-reg-table .table-table {
  max-height: 48vh;
}
.rtu-error-table .table-table {
  max-height: 22vh;
}

@media print {
  @page {size: auto; margin: 10px 10px 0px 10px;}
  .print-area {zoom: 80%;}
  .title,
  .print-button {display: none; visibility: hidden;}
  .rtu-reg-table .table-table, 
  .rtu-error-table .table-table {max-height: none;}
}
