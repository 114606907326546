@import './CommonPage.scss';

.rtu-set-detail .contents-wrap {
  padding : 0px;
  max-height: 83vh;
  overflow: auto;
}

.rtu-set-detail .flex-wrap {
  display: flex;
  height: 100%;
  gap:10px;
  flex-wrap: nowrap !important;
}

.rtu-set-detail .left-wrap,
.rtu-set-detail .right-wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 50%;
}

.rtu-set-detail .group-box {
  padding : 15px;
  margin: 10px;
}

.rtu-set-detail .group-box h2 {
  padding-left : 0px;
}

.rtu-set-detail .row {
  padding : 10px;
}

.rtu-set-detail .help {
  color:#c5c5c5;
  margin: 3px 0 0 3px;
}